<template>
  <v-container fluid class="pa-0">
    <v-row class="justify-center fill-height overflow-auto" align="center">
      <v-col cols="12" md="5" class="pa-16">
        <v-row class="d-flex flex-wrap mb-2 my-16">
          <div class="d-flex flex-wrap mb-12">
            <img src="@/assets/logo/Logo-Verde.png" height="40px" class="mb-2" />
          </div>
        </v-row>
        <v-row>
          <h1 class="text-h2 accent--text">{{$t('preparando_su_cuenta')}}</h1>
          <p class="white--text">
            {{$t('estamos_preparando_su_cuenta')}}
          </p>
          <v-progress-circular color="white" indeterminate size="130" class="ma-auto mt-8 text-h2" >
            <countdown @finish="$router.push({ name: 'Login' })" :end-time="new Date().getTime() + 300000" class="pt-2">
              <template v-slot:process="anyYouWantedScopName">
                <span>{{ `${anyYouWantedScopName.timeObj.ceil.s}` }}</span>
              </template>
            </countdown>
          </v-progress-circular>
        </v-row>
      </v-col>
      <v-col cols="12" md="7" class="fill-height d-flex" style="background: #2E0D5F">
        <v-row justify="center" class="ma-auto">
          <v-sheet class="mx-auto" elevation="8" width="90%" max-width="90%">
            <v-carousel height="500" hide-delimiter-background show-arrows-on-hover>
              <v-carousel-item>
                <v-sheet color="white" height="100%" class="black--text overflow-auto">
                  <v-container class="pa-8 pa-md-16 align-center">
                    <v-row>
                      <div class="display-3 black--text">{{$t('bienvenido')}}</div>                      
                    </v-row>
                    <v-row >
                      <p>{{$t('mientras_se_prepara_su_cuenta_puede_ver_los_aspectos_fundamentales_de_la_aplicacion_en_esta_pantalla')}}</p>
                    </v-row>
                    <v-row>
                      <v-img src="~@/assets/img/pantallazo-genetic-profile.jpg" class="rounded" width="0"></v-img>
                    </v-row>
                  </v-container>
                </v-sheet>
              </v-carousel-item>
              <v-carousel-item>
                <v-sheet color="white" height="100%" class="black--text overflow-auto">
                  <v-container class="pa-16 align-center">
                    <v-row class="position-sticky">
                      <div class="display-3 black--text">{{$t('paneles')}}</div>                      
                    </v-row>
                    <v-row >
                    </v-row>
                  </v-container>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-sheet>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Pusher from "pusher-js";

export default {
  components: {},
  props: ["id"],
  name: "PagesAccountSetup",
  data() {
    return {
      email: null,
      pusher: null,
      channel: null
    };
  },
  created() {
    let self = this;
    self.pusher = new Pusher("413f5240c36a765dd122", {
      cluster: "eu"
    });
  },
  mounted() {
    let self = this;
    if (self.$route.query.email) {
      self.email = self.$route.query.email;
    }
    setTimeout(function() {
      self.channel = self.pusher.subscribe(self.id);
      self.channel.bind("results-loaded", function(data) {
        self.$router.push({
          name: "Login",
          query: {
            email: self.email
          }
        });
      });
    }, 15000);
  }
};
</script>

<style lang="scss" scoped>
.container.container--fluid {
  height: 100vh;
}
</style>
